
            @import "@/assets/sass/vars.portal.scss";
          
















































































































































































































































































































































































.v-filters {
    &__container {
        //border: 1px dotted green;
        display: flex;
        flex-direction: row;
        padding-right: 15px;
        align-items: center;
        padding-bottom: 18px;

        &__left {
            //border: 1px dotted black;
            flex-grow: 0;
            margin-right: 15px;
        }

        &__search {
            
            .vf-c {
                width: 340px;
            }

            flex: 1 1 350px; 

            display: flex;
            .form-group {
                margin-bottom: 0px;
            }

            > div {
                width: 100%;
            }
        }

        // Duplicate of the above - though w/o search box!
        &__search--filters {
            //border: 1px solid red;
            display: flex;
            flex: 0 0 80px;

            .form-group {
                margin-bottom: 0px;
            }

            > div {
                width: 100%;
            }
        }

        &__right {
            //border: 1px dotted red;
            flex-grow: 1;
            flex-wrap: wrap;
            justify-content: flex-end;
            display: flex;

            .right__filter-elem {
                display: inline-flex;
                flex: 1 1;
                //border: 1px dotted red !important;
                margin-left: 30px;
            }

            & > span {
                margin-left: 30px;
                font-size: 15px;
            }
        }
    }

    @media (max-width: 991.98px) {
        &__container {
            flex-wrap: wrap;
            padding-right: 0;

            &__left {
                flex: 20%;
            }

            &__search {
                flex: 75%;

                .vf-c {
                    width: 420px;
                }
            }

            &__right {
                flex: 100%;
                margin-top: 20px;
            }
        }
    }

    @media (max-width: 767.98px) {
        &__container {
            // flex-direction: column;
            padding-right: 0;
            align-items: left;

            &__left {
                flex: 100%;
                margin-bottom: 40px;
            }

            &__search {
                flex-grow: 0;
                // min-width: 280px !important;
                flex: 100%;
                .form-group {
                    // margin-bottom: 20px;
                }

                .vf-c {
                    width: auto;
                }
            }

            &__filters {
                .col {
                    flex: 100%;
                }

                .range-picker {
                    margin-bottom: 1.5rem;
                }
            }

            &__right {
                flex-wrap: wrap;

                & > span {
                    margin-left: 0;
                    flex: 100%;
                }

                .right__filter-elem {
                    margin-left: 0;
                    display: block;
                    margin-bottom: 15px;
                }
            }

            &__filters {
                margin-bottom: 30px;
            }
        }
    }
}
